<script>
import CouponDialogBase from "./CouponDialogBase";
import VxDialogView from "@/components/vx/VxDialogView";
import { hasSnackbarAccess } from "@/mixins/ui";
import { VxAlert } from "@/core-ui";
import { CREATE_COUPON } from "./graphql/queries";
import { debounce } from "lodash";
import Bus from "@/utils/events";

export default {
  name: "BouncebackNewDialog",
  components: {
    VxDialogView,
    VxAlert,
  },
  extends: CouponDialogBase,
  mixins: [hasSnackbarAccess],
  inheritAttrs: false,
  data: () => ({
    multipleCoupons: false,
    localCoupon: {
      name: "",
      email: "",
      cellphone: "",
      count: 1,
    },
    errorMessage: null,
  }),

  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  watch: {
    computedCellphone: debounce(function (v) {
      if (v && v.match(/\d{10}/)) {
        this.errors.cellphone = [];
      }
      if (v && !v.match(/\d{10}/)) {
        this.errors.cellphone = [
          this.$t("bounceback.app.bouncebackNew.notValidPhone"),
        ];
      }
      if (!v) {
        this.errors.cellphone = [];
      }
    }, 300),
    computedEmail: debounce(function (v) {
      if (!v) {
        this.errors.email = [];
        return true;
      }
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const matchesRegex = v.match(emailRegex);
      if (v && matchesRegex) {
        this.errors.email = [];
      }
      if (v && !matchesRegex) {
        this.errors.email = [
          this.$t("bounceback.app.bouncebackNew.notValidEmail"),
        ];
      }
    }, 300),
  },

  methods: {
    async createNewCoupon() {
      this.loading = true;
      try {
        const variables = {
          input: { ...this.localCoupon },
        };
        variables.input.count = parseInt(variables.input.count, 10);

        const resp = await this.$apollo.mutate({
          mutation: CREATE_COUPON,
          variables,
        });
        if (resp.data.createCoupon.errors.length) {
          throw new Error(resp.data.createCoupon.errors.join("; "));
        }
        Bus.$emit("coupon-created", resp.data.createCoupon.coupons);
        this.showSnackbar({
          text: this.$t("bounceback.app.bouncebackNew.couponCreateSuccess"),
        });
        this.$router.go(-1);
      } catch (err) {
        this.errorMessage = err;
      }
      this.loading = false;
    },
    onMultipleCouponsClick() {
      this.multipleCoupons = true;
    },
  },
};
</script>

<template>
  <VxDialogView
    :title="$t('bounceback.app.bouncebackNew.title')"
    :retain-focus="false"
    :v-size="'medium'"
    :h-size="'xsmall'"
  >
    <template #actions>
      <v-btn
        :color="isSmallScreen ? null : 'primary'"
        text
        :disabled="!formValid"
        :loading="loading"
        @click="createNewCoupon"
        >{{ $t("bounceback.app.bouncebackNew.addButton") }}</v-btn
      >
    </template>
    <template>
      <v-form ref="newCouponForm" autocomplete="no">
        <VxAlert v-if="errorMessage" type="error">{{ errorMessage }}</VxAlert>
        <v-text-field
          v-model="localCoupon.name"
          :label="$t('bounceback.app.bouncebackNew.nameFieldLabel')"
          autocomplete="no"
        />
        <v-text-field
          v-model="localCoupon.email"
          :label="$t('bounceback.app.bouncebackNew.emailFieldLabel')"
          :error-messages="errors.email"
          type="email"
          inputmode="email"
          autocomplete="no"
        />
        <v-text-field
          v-model="localCoupon.cellphone"
          prefix="+1"
          :label="$t('bounceback.app.bouncebackNew.cellphoneFieldLabel')"
          mask="phone"
          type="tel"
          inputmode="numeric"
          pattern="[0-9]*"
          autocomplete="no"
          :error-messages="errors.cellphone"
        />
        <v-text-field
          v-model="localCoupon.transactionId"
          :label="$t('bounceback.app.bouncebackNew.transactionIdLabel')"
          autocomplete="no"
          inputmode="numeric"
          pattern="[0-9]*"
          required
        />
        <v-text-field
          v-if="multipleCoupons"
          v-model="localCoupon.count"
          type="number"
          min="1"
          :label="$t('bounceback.app.bouncebackNew.numberCouponLabel')"
          autocomplete="no"
          inputmode="numeric"
          pattern="[0-9]*"
          required
        />
        <v-btn v-else color="secondary" @click="onMultipleCouponsClick">
          {{ $t("bounceback.app.bouncebackNew.multipleCouponsButton") }}
        </v-btn>
      </v-form>
    </template>
  </VxDialogView>
</template>
